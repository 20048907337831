<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <span class="ml-6">{{ selectedUser.username }}</span>
          </v-card-title>
          <v-card-text>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Phone</v-list-item-title>
                <v-list-item-subtitle>{{ selectedUser.phone }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Email</v-list-item-title>
                <v-list-item-subtitle>{{ selectedUser.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Description</v-list-item-title>
                <v-list-item-subtitle>{{ selectedUser.description }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Birthdate</v-list-item-title>
                <v-list-item-subtitle class="text-capitalize">{{ formatDate(selectedUser.birthdate) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Gender</v-list-item-title>
                <v-list-item-subtitle class="text-capitalize">{{ selectedUser.gender }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Informational Material</v-list-item-title>
                <v-list-item-subtitle class="text-capitalize">{{ selectedUser.newsletter_consent ? 'Yes' : 'No' }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Registration</v-list-item-title>
                <v-list-item-subtitle class="text-capitalize">{{ formatDate(selectedUser.registration_date) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Packages</v-list-item-title>
                <v-list-item-subtitle class="text-capitalize">{{ selectedUser.transactions.length }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Available Circles</v-list-item-title>
                <v-list-item-subtitle class="text-capitalize">{{ selectedUser.available_circles }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>User Circles</v-list-item-title>
                <v-list-item-subtitle>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th>
                          Name
                        </th>
                        <th>
                          Type
                        </th>
                        <th>
                          Country
                        </th>
                        <th>
                          Rating
                        </th>
                        <th>
                          Users
                        </th>
                        <th>
                          Commercial
                        </th>
                        <th>
                          Reports
                        </th>
                        <th>
                          Created
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr
                          v-for="circle in circles"
                          :key="circle.id"
                      >
                        <td>{{ circle.name }}</td>
                        <td><span class="text-capitalize">{{ circle.type }}</span></td>
                        <td><country-flag :country="circle.metadata.country" v-if="circle.metadata && circle.metadata.country"/></td>
                        <td>
                          <v-rating
                              :value="circle.rating"
                              background-color="orange lighten-3"
                              color="orange"
                              half-increments
                              readonly
                              dense
                              small
                          ></v-rating>
                        </td>
                        <td>{{ circle.membersCount }}</td>
                        <td>{{ circle.metadata.isCommercial ? 'Yes' : 'No' }}</td>
                        <td>{{ circle.reports.length }}</td>
                        <td>{{ formatDateTime(circle.createdAt) }}</td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapState} from "vuex"
import dateFormat from '../mixins/date'
import CountryFlag from 'vue-country-flag'

export default {
  name: "UserDetails",
  mixins: [dateFormat],
  components: {CountryFlag},
  data: () => ({
    search: '',
    typeSearch: null,
    createdSearch: null,
    circles: []
  }),
  mounted() {
    this.axios.get(`users/${this.selectedUser.id}/circles`).then(resp => {
      this.circles = resp.data
    });
  },
  computed: {
    ...mapState(['selectedUser'])
  },
  methods: {
    viewUser(user) {
      this.userDetailsUsername = user.username
      this.userDetailsDialog = true
    }
  }
}
</script>

<style scoped>

</style>