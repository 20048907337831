var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v(" Users "),_c('v-spacer'),_c('export-excel',{attrs:{"data":_vm.usersExport,"name":"users.xls"}},[_c('v-btn',{attrs:{"small":""}},[_vm._v("Export to Excel")])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.all_users,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('v-select',{attrs:{"items":[{text: 'Filter by gender', value: null}, {text: 'Male', value: 'Male'}, {text: 'Female', value:'Female'}, {text: 'Other', value:'Other'}],"placeholder":"Gender"},model:{value:(_vm.genderSearch),callback:function ($$v) {_vm.genderSearch=$$v},expression:"genderSearch"}})],1),_c('v-col',[_c('v-select',{attrs:{"items":[{text: 'Filter by informational material', value: null}, {text: 'Yes', value: true}, {text: 'No', value: false}],"placeholder":"Informational material"},model:{value:(_vm.informationalMaterialSearch),callback:function ($$v) {_vm.informationalMaterialSearch=$$v},expression:"informationalMaterialSearch"}})],1),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"placeholder":"Birthdate","prepend-icon":"mdi-calendar","readonly":"","clearable":""},model:{value:(_vm.birthdateRangeText),callback:function ($$v) {_vm.birthdateRangeText=$$v},expression:"birthdateRangeText"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"range":""},model:{value:(_vm.birthdateSearch),callback:function ($$v) {_vm.birthdateSearch=$$v},expression:"birthdateSearch"}})],1)],1),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"placeholder":"Registration Date","prepend-icon":"mdi-calendar","readonly":"","clearable":""},model:{value:(_vm.registrationRangeText),callback:function ($$v) {_vm.registrationRangeText=$$v},expression:"registrationRangeText"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"range":""},model:{value:(_vm.registrationDateSearch),callback:function ($$v) {_vm.registrationDateSearch=$$v},expression:"registrationDateSearch"}})],1)],1)],1)],1)]},proxy:true},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{'green--text': item.verification.email_verified, 'red--text': !item.verification.email_verified}},[_vm._v(_vm._s(item.email))])]}},{key:"item.birthdate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.birthdate))+" ")]}},{key:"item.newsletter_consent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.newsletter_consent ? 'Yes' : 'No')+" ")]}},{key:"item.registration_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateTime(item.registration_date))+" ")]}},{key:"item.transactions",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.transactions.length)+" ")]}},{key:"item.gender",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.gender || '-'))])]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{'green--text': item.active, 'red--text': !item.active}},[_vm._v(_vm._s(item.active ? 'Yes' : 'No'))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_c('v-btn',{attrs:{"icon":"","color":"default"},on:{"click":function($event){return _vm.viewUser(item)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1)],1),_c('td',{staticClass:"text-center"},[(item.active === true)?_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.deactivateUser(item.id)}}},[_c('v-icon',[_vm._v("mdi-account-cancel")])],1):_c('v-btn',{attrs:{"icon":"","color":"success"},on:{"click":function($event){return _vm.activateUser(item.id)}}},[_c('v-icon',[_vm._v("mdi-account-check")])],1)],1),_c('td',{staticClass:"text-center"},[_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.deleteUser(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]}}])})],1)],1)],1),_c('confirm-dialog',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }