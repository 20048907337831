<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Users
            <v-spacer></v-spacer>
            <export-excel
                :data="usersExport"
                name="users.xls"
            >
              <v-btn small>Export to Excel</v-btn>
            </export-excel>
          </v-card-title>
          <v-data-table
              :headers="headers"
              :items="all_users"
              :search="search"
          >
            <template v-slot:top>
              <v-container fluid>
                <v-row>
                  <v-col>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-select
                        :items="[{text: 'Filter by gender', value: null}, {text: 'Male', value: 'Male'}, {text: 'Female', value:'Female'}, {text: 'Other', value:'Other'}]"
                        placeholder="Gender" v-model="genderSearch">
                    </v-select>
                  </v-col>
                  <v-col>
                    <v-select
                        :items="[{text: 'Filter by informational material', value: null}, {text: 'Yes', value: true}, {text: 'No', value: false}]"
                        placeholder="Informational material" v-model="informationalMaterialSearch">
                    </v-select>
                  </v-col>
                  <v-col>
                    <v-menu
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="birthdateRangeText"
                            placeholder="Birthdate"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            clearable
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="birthdateSearch"
                          range
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col>
                    <v-menu
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="registrationRangeText"
                            placeholder="Registration Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            clearable
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="registrationDateSearch"
                          range
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-container>
            </template>
            <template v-slot:item.email="{ item }">
              <span
                  :class="{'green--text': item.verification.email_verified, 'red--text': !item.verification.email_verified}">{{
                  item.email
                }}</span>
            </template>
            <template v-slot:item.birthdate="{ item }">
              {{ formatDate(item.birthdate) }}
            </template>
            <template v-slot:item.newsletter_consent="{ item }">
              {{ item.newsletter_consent ? 'Yes' : 'No' }}
            </template>
            <template v-slot:item.registration_date="{ item }">
              {{ formatDateTime(item.registration_date) }}
            </template>
            <template v-slot:item.transactions="{ item }">
              {{ item.transactions.length }}
            </template>
            <template v-slot:item.gender="{ item }">
              <span>{{ item.gender || '-' }}</span>
            </template>
            <template v-slot:item.active="{ item }">
              <span
                  :class="{'green--text': item.active, 'red--text': !item.active}">{{
                  item.active ? 'Yes' : 'No'
                }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
              <td class="text-center">
                <v-btn icon color="default" @click="viewUser(item)">
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </td>
              <td class="text-center">
                <v-btn icon color="error" @click="deactivateUser(item.id)" v-if="item.active === true">
                  <v-icon>mdi-account-cancel</v-icon>
                </v-btn>
                <v-btn icon color="success" @click="activateUser(item.id)" v-else>
                  <v-icon>mdi-account-check</v-icon>
                </v-btn>
              </td>
              <td class="text-center">
                <v-btn icon color="error" @click="deleteUser(item.id)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <confirm-dialog ref="confirm"></confirm-dialog>
  </v-container>
</template>

<script>
import {mapState} from "vuex";
import dateUtils from '../mixins/date';
import ConfirmDialog from "@/components/ConfirmDialog";

export default {
  name: "Users",
  components: {ConfirmDialog},
  mixins: [dateUtils],
  data: () => ({
    search: '',
    genderSearch: null,
    birthdateSearch: null,
    registrationDateSearch: null,
    informationalMaterialSearch: null,
    dialog: false,
    all_users: []
  }),
  computed: {
    ...mapState(['users']),
    headers() {
      return [
        {text: 'Username', value: 'username'},
        {text: 'Phone', value: 'phone'},
        {text: 'Email', value: 'email'},
        {text: 'Birthdate', value: 'birthdate', filter: this.filterBirthdate},
        {text: 'Gender', value: 'gender', filter: this.filterGender},
        {text: 'Informational material', value: 'newsletter_consent', filter: this.filterInformationalMaterial},
        {text: 'Registration', value: 'registration_date', filter: this.filterRegistrationDate},
        {text: 'Packages', value: 'transactions', filterable: false},
        {text: 'Avail. Circles', value: 'available_circles', filterable: false},
        {text: 'Active', value: 'active', filterable: true, sortable: false},
        {text: 'Actions', value: 'actions', filterable: false, sortable: false},
      ]
    },
    birthdateRangeText: {
      get() {
        if (!this.birthdateSearch) {
          return ''
        }
        const dates = []
        this.birthdateSearch.forEach(date => {
          dates.push(this.formatDate(date))
        })
        return dates.join(' - ')
      },
      set() {
        this.birthdateSearch = null
      }
    },
    registrationRangeText: {
      get() {
        if (!this.registrationDateSearch) {
          return ''
        }
        const dates = []
        this.registrationDateSearch.forEach(date => {
          dates.push(this.formatDate(date))
        })
        return dates.join(' - ')
      },
      set() {
        this.registrationDateSearch = null
      }
    },
    usersExport() {
      const usersExport = []
      this.all_users.forEach(user => {
        usersExport.push({
          'Username': user.username,
          'Phone': user.phone,
          'Email': user.email,
          'Email Verified': user.verification.email_verified ? 'Yes' : 'No',
          'Description': user.description,
          'Birthdate': this.formatDate(user.birthdate),
          'Gender': user.gender,
          'Informational Material': user.newsletter_consent ? 'Yes' : 'No',
          'Registration': this.formatDateTime(user.registration_date),
          'Packages': user.transactions.length,
          'Available Circles': user.available_circles,
        })
      })

      return usersExport
    }
  },
  mounted() {
    this.fetchUsers()
  },
  methods: {
    fetchUsers() {
      this.$axios.get('/users').then(resp => {
        this.all_users = resp.data
      })
    },
    filterGender(value) {
      if (!this.genderSearch) {
        return true;
      }

      return value === this.genderSearch
    },
    filterBirthdate(value) {
      if (!this.birthdateSearch) {
        return true;
      }

      return this.isDateInRange(value, this.birthdateSearch[0], this.birthdateSearch[1])
    },
    filterRegistrationDate(value) {
      if (!this.registrationDateSearch) {
        return true;
      }

      return this.isDateInRange(value, this.registrationDateSearch[0], this.registrationDateSearch[1])
    },
    filterInformationalMaterial(value) {
      if (this.informationalMaterialSearch === null) {
        return true;
      }

      return value === this.informationalMaterialSearch
    },
    viewUser(user) {
      this.$store.commit('selectUser', user)
      this.$router.push('/users/' + user.id)
    },
    async deleteUser(id) {
      if (
          await this.$refs.confirm.open(
              "Confirm",
              "Are you sure you want to delete this user?"
          )
      ) {
        this.$axios.delete(`/users/${id}`).then(() => {
          this.fetchUsers()
        })
      }
    },
    async activateUser(id) {
      if (
          await this.$refs.confirm.open(
              "Confirm",
              "Are you sure you want to activate this user?"
          )
      ) {
        this.$axios.put(`/users/${id}/activate`).then(() => {
          this.fetchUsers()
        })
      }
    },
    async deactivateUser(id) {
      if (
          await this.$refs.confirm.open(
              "Confirm",
              "Are you sure you want to deactivate this user?"
          )
      ) {
        this.$axios.put(`/users/${id}/deactivate`).then(() => {
          this.fetchUsers()
        })
      }
    }
  }
}
</script>

<style scoped>

</style>